import http from '@/http-common'

export class ApiService {
    index(page, params) {
      var queryUrl = ''
      if (params && page) {
        const queryParams = Object.keys(params).map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k])).join('&');
        queryUrl = `?page=` + page + `&` + queryParams
      }
        return http.get(`${this.getUrl()}` + queryUrl)
    }
    show(id) {
      return http.get(`${this.getUrl()}/${id}`)
    }
    store(data) {
      return http.post(`${this.getUrl()}`, data)
    }
    update(data) {
      return http.put(`${this.getUrl()}/${data.id}`, data)
    }
    delete(id) {
      return http.delete(`${this.getUrl()}/${id}`)
    }
}

export default new ApiService()