<template>

  <div class="home">
    <h1>Dashboard </h1>
    Het is vandaag {{formatDate(new Date(), 'longDate')}}

    <div class="col-12">
      <h4 class="mt-4"><strong>In ontwikkeling  <i class="pi pi-megaphone"></i> </strong></h4>
        <template v-for="tba in announcements" :key="tba.id">
          <template v-if="tba.tba == 1">

        <div class="card">
          <div class="card-header">
            {{tba.title}}
          </div>
          <div class="card-body">
            <h5 class="card-title">{{tba.description}}</h5>
          </div>
          </div>
          </template>
        </template>

      <h4 class="mt-4"><strong>Updates <i class="pi pi-cog"></i></strong></h4>
      <template v-for="announcement, index in announcements" :key="announcement.id">
        <div class="card">
          <div class="card-header"><strong>{{formatDate(announcement.start_date, 'longDate')}}</strong> - {{announcement.title}} </div>
         
         <div class="card-body">
          <h5 class="card-title">{{announcement.description}} <span @click='expand(index)' class="bs">
            <template v-if="announcement.visible">
              <i class="pi pi-chevron-circle-up" title="Lees minder"></i>
            </template>
            <template v-else>
              <i class="pi pi-chevron-circle-down" title="Lees meer"></i>
            </template>
          </span></h5>
          <template v-if="announcement.tba != 1">
            <div class="card-text" v-show="announcement.visible"><p style="font-weight: lighter; font-style: italic;">{{announcement.information}}</p></div>
          </template>
        </div>
        </div>
        <br>
      </template>
      
    </div>
  </div>
</template>

<script>
  import homeService from '@/services/HomeService';
  import destinationService from '@/services/DestinationService'
  import router from "@/router";

  export default {
    data() {
      return {
        announcements: []
      }
    },
    methods: {
      expand(index){
            this.announcements[index].visible = !this.announcements[index].visible;
          },
      handleCallback(code, state) {
        destinationService.handleCallback(code, state).then(response => {
        console.log(code);
          console.log(response)
          router.push("/connections");
        }).catch(error => {
          console.log('error', error) // create notification-toaster for user error
        })
      },
      getAnnouncements() {
        homeService.index().then(response => {
          this.announcements = response.data
        }).catch(error => {
          console.log('error', error)
          this.toastError(error)
        })
      },
    },
    mounted() {
      const urlParams = new URLSearchParams(window.location.search);
      const code = urlParams.get('code');
      const state = urlParams.get('state');
      if (code && state) {
        this.handleCallback(code, state)
      }

      this.getAnnouncements();
      this.itemFormRef = this.$refs.ItemForm;

    }
  }
</script>